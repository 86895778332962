<script>
    export default {
        setup() {
            const auth = useAuthStore();
            const notification = useNotificationStore();
            return { auth, notification };
        },
        data() {
            return {
                onLine: navigator.onLine,
                showBackOnline: false
            };
        },
        computed: {
            ...mapGetters(useAuthStore, ['hasSession']),
            ...mapGetters(useResponseStore, ['htmlError', 'errors'])
        },
        created() {
            if (!this.hasSession) {
                this.$router.replace('/auths/login');
            }
        },
        methods: {
            ...mapActions(useAuthStore, ['loadSession', 'getMe', 'getPermission']),
            popupNotif(eventName, data) {
                if (data.title) {
                    this.$swal({
                        icon: 'success',
                        position: 'bottom-end',
                        showConfirmButton: false,
                        title: data.title,
                        html: data.message,
                        timer: 5000,
                        toast: true
                    }).then(async () => {
                        await this.notification.fetchAll().catch((err) => {});
                        // await this.getPermission().catch(err => { });
                        // await this.getMe().catch(err => { });
                        await this.loadSession();

                        if ('Notification' in window) {
                            Notification.requestPermission().then((permission) => {
                                if (permission === 'granted') {
                                    if ('serviceWorker' in navigator) {
                                        // Use service worker for mobile notifications
                                        navigator.serviceWorker.ready.then((registration) => {
                                            registration.showNotification(data.title, {
                                                body: data.message,
                                                icon: '/favicon.ico'
                                            });
                                        });
                                    } else {
                                        // Use regular Notification for desktop
                                        const notification = new Notification(data.title, {
                                            body: data.message,
                                            icon: '/favicon.ico'
                                        });

                                        // You can handle click events on the notification
                                        notification.onclick = () => {
                                            window.location = window.location.origin + data.link;
                                        };
                                    }
                                }
                            });
                        } else {
                            console.error('Notification API not supported in this browser');
                        }
                    });
                }
            },
            updateOnlineStatus(e) {
                const { type } = e;
                this.onLine = type === 'online';
            }
        },
        mounted() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker
                    .register('/sw.js')
                    .then((registration) => {
                        console.log('Service Worker registered with scope:', registration.scope);
                    })
                    .catch((error) => {
                        console.error('Service Worker registration failed:', error);
                    });
            }

            // this.loadSession();
            if (!this.auth) return;
            if (!this.auth.token) return;
            if (!this.auth.user) return;

            if ('Notification' in window) {
                Notification.requestPermission().then((permission) => {
                    console.info('Notification Granted');
                });
            }

            const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
                cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
                wsHost: import.meta.env.VITE_PUSHER_HOST,
                wsPort: import.meta.env.VITE_PUSHER_PORT,
                // useTLS: false,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ['ws'],

                channelAuthorization: {
                    endpoint: '/broadcasting/auth',
                    headers: { Authorization: 'Bearer ' + this.auth.token }
                }
            });
            const channel = pusher.subscribe('private-App.' + this.auth.user.id);

            channel.bind_global(this.popupNotif);
            window.addEventListener('online', this.updateOnlineStatus);
            window.addEventListener('offline', this.updateOnlineStatus);
        },
        beforeDestroy() {
            window.removeEventListener('online', this.updateOnlineStatus);
            window.removeEventListener('offline', this.updateOnlineStatus);
        },
        watch: {
            onLine(v) {
                if (v) {
                    this.showBackOnline = true;
                    setTimeout(() => {
                        this.showBackOnline = false;
                    }, 1000);
                }
            }
        }
    };
</script>
<template>
    <div v-if="onLine">
        <DashboardHeader />
        <DashboardNavigation />
        <div class="container show margin-bottom-large" id="container">
            <RouterView />
            <div style="height: 60px" class="uk-hidden@m"></div>
        </div>
        <DashboardFooter />
    </div>
    <Offline v-else />
</template>
