<script>
    export default {
        setup() {
            const auth = useAuthStore();
            const { t } = useI18n();
            return { auth, t };
        },
        data() {
            return {
                search: null,
                filter: []
            };
        },
        methods: {
            async endSession() {
                await this.$swal({
                    title: this.t('End This Session?'),
                    icon: 'warning',
                    showCancelButton: true

                    //confirmButtonText: 'Yes, ' + this.helper.capitalize(plan) + '!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.auth.logout();
                        this.$router.replace('/auths/login');
                    }
                });
            },
            openModal() {
                UIKit.dropdown('#navigation-search').show();
            },
            closeModal() {
                UIKit.dropdown('#navigation-search').hide(false);
                this.search = null;
            }
        },
        watch: {
            search: {
                handler(payload) {
                    if (payload && payload.length > 0) {
                        this.filter = [];
                        const liElements = this.$refs.navigation.$refs.navigation.getElementsByTagName('li');
                        const liArray = [...liElements];

                        const filtered = liArray.filter((element) => {
                            return element.innerText.toLowerCase().includes(payload.toLowerCase());
                        });

                        filtered.forEach((element) => {
                            let link = null;
                            if (element.firstChild.href) {
                                link = element.firstChild.href.replace(window.location.origin, '');
                            }
                            this.filter.push({
                                title: element.innerText,
                                className: element.className,
                                link: link,
                                el: element
                            });
                        });
                    }
                }
            }
        }
    };
</script>

<template>
    <nav
        uk-navbar
        class="navigation-side uk-navbar-container uk-navbar-left uk-navbar-transparent show"
        uk-toggle="target: .navigation-side, .container; cls: show;  mode:media; media: @s"
    >
        <div class="navigation-side-toggle uk-padding-small uk-padding-remove-horizontal uk-margin-remove-top">
            <div class="uk-grid-collapse" uk-grid>
                <div class="uk-width-auto">
                    <button
                        uk-toggle="target: .navigation-side, .container; cls: collapsed;  animation: uk-animation-slide-right-medium;"
                        type="button"
                        class="button-menu uk-button uk-button-default uk-button-icon uk-width-1-1"
                    >
                        <LayoutDashboardIcon />
                    </button>
                </div>
                <div class="uk-width-expand">
                    <RouterLink
                        to="/dashboard"
                        class="button-add uk-button uk-button-default uk-preserve-width uk-padding-remove uk-border-rounded"
                    >
                        <div class="uk-grid-collapse frame uk-border-rounded" uk-grid>
                            <div class="uk-width-expand label">
                                <span class="navigation-side-label uk-padding-small">
                                    {{ $t('Dashboard') }}
                                </span>
                            </div>
                        </div>
                    </RouterLink>
                </div>
            </div>

            <div class="uk-padding-small uk-margin-small-top navigation-side-label">
                <div class="uk-form-controls uk-inline uk-width-1-1">
                    <div>
                        <input
                            id="search_menu"
                            v-on:keypress="openModal"
                            v-model="search"
                            @input="openModal"
                            @focus="openModal"
                            type="text"
                            class="uk-input uk-form-small"
                            :placeholder="`${$t('Search Menu')}`"
                        />
                        <span class="uk-form-icon uk-form-icon-flip">
                            <SearchIcon />
                        </span>
                    </div>
                </div>
                <div
                    id="navigation-search"
                    class="uk-padding-small uk-width-expand rounded-soft"
                    uk-dropdown="animation: slide-top; mode: click; offset: 2"
                >
                    <template v-if="filter && filter.length > 0">
                        <ul uk-nav>
                            <li :class="list.className" v-for="list in filter" :key="list">
                                <RouterLink :to="list.link" v-if="list.link" @click="closeModal">
                                    <span class="navigation-side-label">
                                        {{ $t(list.title) }}
                                    </span>
                                </RouterLink>
                                <span v-else class="navigation-side-label">
                                    <b>{{ $t(list.title) }}</b>
                                </span>
                            </li>
                        </ul>
                    </template>
                    <span class="uk-text-small" v-else>
                        {{ $t('Type to search') }}
                    </span>
                </div>
            </div>

            <DashboardNavigationLink ref="navigation" />

            <div class="uk-padding-small uk-padding-remove-horizontal" style="background-color: #fff">
                <button @click="endSession" class="button-add uk-button uk-padding-remove">
                    <div class="uk-flex uk-flex-middle button-menu uk-border-rounded uk-grid-collapse" uk-grid>
                        <div class="uk-flex uk-flex-middle uk-margin-small-left">
                            <LoginIcon />
                        </div>
                        <div>
                            <span class="navigation-side-label uk-margin-small-left">
                                {{ $t('Logout') }}
                            </span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </nav>
</template>

<style scoped>
    .profile-picture {
        width: 200px;
        height: 200px;
    }
</style>
