<script>
    export default {
        props: {
            type: String,
            navigations: Array
        },
        setup() {
            const auth = useAuthStore();
            const can = auth.can;
            const module = useModuleStore();
            return { can, module };
        },
        methods: {
            isShow(payload) {
                let show = true;
                if (payload.module) {
                    payload.module.forEach((module) => {
                        if (show && this.module.checkModule(module) == false) show = false;
                    });
                }
                return show;
            },
            hasPermission(permission) {
                let granted = false;
                if (permission instanceof Array) {
                    permission.forEach((item) => {
                        if (this.can(`${item}-index`)) granted = true;
                    });
                } else {
                    granted = this.can(`${permission}-index`);
                }
                return granted;
            }
        }
        // can(`${tab.permission}-index`)
    };
</script>

<template>
    <template v-for="(tab, index) in navigations" :key="index">
        <li
            v-if="isShow(tab) && (hasPermission(tab.permission) || !tab.permission)"
            class="uk-nav-header uk-margin-remove-top"
        >
            <span class="navigation-side-label">
                <b>{{ $t(tab.title) }}</b>
            </span>
        </li>
        <template v-for="tab in tab.children" :key="tab.link">
            <li
                v-if="isShow(tab) && hasPermission(tab.permission)"
                class="link"
                :class="{ active: $route.path == tab.link }"
            >
                <RouterLink :to="tab.link">
                    <span class="uk-padding-small uk-padding-remove-horizontal uk-flex uk-flex-middle">
                        <component v-bind:is="tab.icon" />
                    </span>
                    <span class="navigation-side-label">
                        {{ $t(tab.title) }}
                    </span>
                </RouterLink>
            </li>
        </template>
    </template>
</template>
