<script>
    export default {
        setup() {
            const auth = useAuthStore();
            const module = useModuleStore();
            const can = auth.can;
            const navigationStore = useNavigationStore();
            return { auth, can, module, navigationStore };
        }
    };
</script>

<template>
    <ul ref="navigation" v-if="module.module && Object.keys(module.module).length > 0" uk-nav class="uk-width-1-1">
        <li v-if="!auth.permission || auth?.permission?.length == 0" class="uk-text-center">
            <div class="uk-spinner uk-text-center" uk-spinner="ratio: 3"></div>
        </li>
        <PartialDashboardLink :navigations="navigationStore.navigation" />
        <div class="uk-padding uk-hidden@s"></div>
    </ul>
</template>
