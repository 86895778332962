<script>
    export default {
        setup() {
            const auth = useAuthStore();
            const can = auth.can;
            const helper = useHelperStore();
            const response = useResponseStore();
            const store = useNotificationStore();
            return { auth, can, helper, response, store };
        },
        mounted() {
            this.store
                .fetchAll({
                    isRead: 0
                })
                .catch((error) => {});
        },
        methods: {
            markAsRead(payload) {
                this.store
                    .markAsRead(payload)
                    .then(() => {
                        this.store
                            .fetchAll({
                                isRead: 0
                            })
                            .catch((error) => {});
                    })
                    .catch((error) => {});
            }
        }
    };
</script>

<template>
    <button class="uk-button uk-button-small uk-button-default uk-padding-remove">
        <BellIcon />
        <div v-if="store.list" class="notification-badge">
            {{ store.total }}
        </div>
    </button>
    <div class="uk-dropdown-large" uk-dropdown="mode: click; offset: 20;">
        <ul class="uk-nav uk-dropdown-nav uk-nav-divider">
            <template v-if="store.list && store.total != 0">
                <li v-for="message in store.list" :key="message.id">
                    <RouterLink :to="message.data.link" @click="markAsRead(message.id)">
                        <span class="navigation-side-label">
                            <strong class="uk-margin-remove uk-text-bold">
                                {{ message.data.title }}
                            </strong>
                            <div class="uk-text-small uk-text-muted" v-html="message.data.message" />
                            <div class="uk-text-small uk-text-muted" v-html="message.createdAt" />
                        </span>
                    </RouterLink>
                </li>
            </template>
            <li v-else>
                <div>{{ $t("There's no new notification") }}</div>
            </li>
            <li>
                <RouterLink to="/dashboard/profile/notifications">
                    <NotificationIcon />
                    {{ $t('View All Notification') }}
                </RouterLink>
            </li>
        </ul>
    </div>
</template>
